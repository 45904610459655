.wf-loading:after {
  content: " ";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  color: #135040;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 20em;
  text-align: center;
}

.wf-loading * {
  opacity: 0;
}
  

body {
  font-family: 'Verdana', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wf-loading {
  background: url('/src/images/logos/solenergylogo.gif');
  height: 100%;
  overflow: hidden;
}

.wf-active *, 
.wf-inactive * {  
  transition: opacity 0.5s ease-out;  
}

@font-face {
  font-family: 'Verdana';
  src: url(/static/media/verdana.7652f550.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GE Inspira';
  src: url(/static/media/GEInspiraRegular.6c73954e.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url(/static/media/Calibri.5d7c31b2.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.suspense {
  width: 100%;
  margin: 0 auto;
}






